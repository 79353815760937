@media screen and (max-width: 750px) {
  .characterHeader img {
    max-width: 50px !important;
  }
  h2 {
    font-size: 18px;
  }
  .c-name {
    font-size: 16px !important;
  }
  .MuiGrid-root {
    padding: 0 !important;
  }
  .css-1tejnxy {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .MuiCardContent-root {
    padding-bottom: 6px !important;
  }
  .css-86ph3b-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12px 14px !important;
  }
}
